import './App.css';
import Main from './main.js';
import ElevatedBar from './elevatedBar.js';

function App() {
  return (

    <div className="App">
       <ElevatedBar/>
      <header className="App-header">
      </header>
    </div>
  );
}

export default App;
